import { useNotification } from '@/containers'
import Link from 'next/link'
import ImageOptimize from '../ImageOptimize'

export const HeaderLogo = () => {
  const { systemConfig } = useNotification()
  return (
    <Link className='mx-auto flex h-[78px] w-auto justify-center' href={'/'}>
      {systemConfig?.companyLogoHeader && (
        <ImageOptimize
          quality={100}
          className='h-full max-h-full w-auto object-contain'
          alt={systemConfig?.websiteBrandName || ''}
          src={systemConfig?.companyLogoHeader || ''}
          priority={true}
          loading='eager'
        />
      )}
    </Link>
  )
}
